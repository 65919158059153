body,html{
  margin:0px;
}

.App-header{
  list-style-type: none;
  text-decoration: none;
  background-color: cornflowerblue;
  padding:20px 20px;
  margin:0px;
  color:white;
  font-size:20px;
}

.App-header a{
  float:right;
  color: white;
  
  text-decoration: none;
}